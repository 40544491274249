/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default [
  {
    title: "Product demos",
    description: "Explore the enterprise-level features that Radiens-AI offers.",
    items: [
      {
        videoHref: "https://www.youtube.com/embed/DZdv5LjlWgk?si=njfWSsYooHxXfTDB",
        name: "Intuitive Recording",
      },
      {
        videoHref: "https://www.youtube.com/embed/4e-gtMudOkE?si=sXDZ1IzN5P-R_EBy",
        name: "Real-time Signal Quality Metrics",
      },
      {
        videoHref: "https://www.youtube.com/embed/q9bZVqhFbgk?si=90E0NIAz1S4B9wNs",
        name: "Raster Plot",
      },
      {
        videoHref: "https://www.youtube.com/embed/0YumdfA8jss?si=yhHNk8xnxsoGG4dQ",
        name: "Real-time Spike Sorting Platform",
      },
      {
        videoHref: "https://www.youtube.com/embed/Zy63X1aMGTM?si=1AMFzrf0IHFtE2lV",
        name: "Relate electrophysiology to anatomy in the moment",
      },
      {
        videoHref: "https://www.youtube.com/embed/7ZF7ZDG76Zk?si=4FKzwIzUnIvoKAn-",
        name: "HD Snapshot",
      },
      {
        videoHref: "https://www.youtube.com/embed/KcK_JrPwLJk?si=MlxDvXugO12Q6pMH",
        name: "Signal Processing",
      },
      {
        videoHref: "https://www.youtube.com/embed/oEJLZtDNFhE?si=EMq4-knKBM-bOLOX",
        name: "Allego - EEG Probe",
      },
      {
        videoHref: "https://www.youtube.com/embed/5pIedrsul5U?si=uAFNuPcoXbnu_sMd",
        name: "Videre - File-based analysis",
      },
    ],
  },
];
