// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import CheckCircle from "@mui/icons-material/CheckCircle";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKAvatar from "components/MKAvatar";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import Table from "examples/Tables/Table";

// Imags
import bgImageFeatures from "assets/images/bg-stock-photo-office.jpg";

// Components
function Author({ image, name, email }) {
  return (
    <MKBox display="flex" alignItems="center" px={1} py={0.5}>
      <MKBox mr={2}>
        <MKAvatar src={image} alt={name} size="sm" variant="rounded" />
      </MKBox>
      <MKBox display="flex" flexDirection="column">
        <MKTypography variant="button" fontWeight="medium">
          {name}
        </MKTypography>
        <MKTypography variant="caption" color="secondary">
          {email}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the Author
Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

function Role({ job, org }) {
  return (
    <MKBox display="flex" flexDirection="column">
      <MKTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </MKTypography>
      <MKTypography variant="caption" color="secondary">
        {org}
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the Role
Role.propTypes = {
  job: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
};

function ComparisonTable() {
  const included = <CheckCircle color="success" fontSize="medium" />;
  const excluded = <RemoveCircleOutline color="grey" fontSize="medium" />;

  const { columns, rows } = {
    columns: [
      { name: "feature", align: "left" },
      { name: "SEEK", align: "center" },
      { name: "LIVE", align: "center" },
      { name: "full suite", align: "center" },
    ],

    rows: [
      {
        feature: "Reading from existing data files",
        SEEK: included,
        LIVE: excluded,
        "full suite": included,
      },
      {
        feature: "DAQ Control",
        SEEK: excluded,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Event detection",
        SEEK: excluded,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Impedance check: magnitude and phase",
        SEEK: excluded,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "MPM integration",
        SEEK: excluded,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "3D brain model",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Audio monitor: site selection",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Channel filtering",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Common average referencing",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Customizable app layout",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Data processing splitter",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "HD snapshot",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "MATLAB API",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Paired referencing",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Probe mapping",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Python API",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Reorder recording sites",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Save and load settings",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Signal metrics heatmap",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Signal processing: filters",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Spike recording sites",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Sorting recording sites by depth",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Spike detector",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
      {
        feature: "Spike raster",
        SEEK: included,
        LIVE: included,
        "full suite": included,
      },
    ],
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 7 }}>
      <MKBox
        borderRadius="xl"
        shadow="lg"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImageFeatures})`,
          backgroundSize: "cover",
        }}
      >
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h3" color="white" mb={2}>
              Package comparison
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox mt={{ xs: -10, lg: -17 }}>
        <Container>
          <Grid container item xs={12} lg={12} mx="auto">
            <Table columns={columns} rows={rows} />
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default ComparisonTable;
