/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// Images
import bgImage from "assets/images/allego-spikes.gif";

function WhyChooseRadiens() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <MKBox
          height="22rem"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            mx: "-1rem",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <MKTypography variant="h2" mb={1} ml={2} color="white">
            Why choose Radiens-AI?
          </MKTypography>
        </MKBox>
        <MKTypography variant="body2" mb={2} mt={1}>
          Why not just use free, open-source software rather than paying for an enterprise solution
          like Radiens? Given enough time, training, self-study, and help, it might be possible to
          DIY an open-source solution for your lab.
        </MKTypography>
        <MKTypography variant="body2" mb={2}>
          But Ephys is progressing at an increasing rate. Today, it's straightforward to conduct a
          sophisticated experiment with 100s of channels and recording hours resulting in terabytes
          of raw data. However, it's becoming more difficult to efficiently curate, analyze, and
          pull meaning from the resulting datasets, given how large and dense they are.
        </MKTypography>
        <MKTypography variant="body2" mb={2}>
          This is where Radiens-AI comes in: it allows you to{" "}
          <strong>
            spend more of your time and focus on the science at hand rather than developing and
            maintaining your data acquisition and workflow systems.
          </strong>{" "}
          Its upgradeability helps to future-proof your lab setup. And no more frustrating hours
          spent debugging and upkeeping in-house, DIY solutions.
        </MKTypography>
      </Container>
    </MKBox>
  );
}

export default WhyChooseRadiens;
