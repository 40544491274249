/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as process from "process";

import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

import { useEffect, useState } from "react";

import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";

import { fetchAuthSession } from "aws-amplify/auth";

import axios from "axios";

const USER_URL = process.env["REACT_APP_USER_URL"];

export default function SignInBasicPage() {
  const [userId, setUserId] = useState(undefined);
  const [userName, setUserName] = useState(undefined);
  const [plan, setPlan] = useState(undefined);

  useEffect(() => {
    fetchAuthSession()
      .then((session) => {
        console.log(session);
        setUserName(session?.tokens?.idToken?.payload?.name);
        const idToken = session?.tokens?.idToken?.toString();
        if (idToken && userId) {
          console.log("getting user", userId, idToken);
          return axios.get(USER_URL + userId, {
            headers: {
              Authorization: idToken,
            },
          });
        }
      })
      .then((r) => {
        console.log(r);
        setPlan(r?.data?.plan[0]);
      })
      .catch(console.err);
  }, [userId]);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/products/chat",
            label: "get a quote",
            color: "info",
          }}
        />
      </MKBox>
      <div style={{ height: "100px" }}></div>
      <Authenticator>
        {({ signOut, user }) => {
          setUserId(user.userId);
          return (
            <main>
              <h2>Hello {userName || user.username}</h2>
              <h3>Subscription: {plan}</h3>
              <button onClick={signOut}>Sign out</button>
            </main>
          );
        }}
      </Authenticator>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
