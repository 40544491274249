/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Grid from "@mui/material/Grid";

import axios from "axios";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// Routes

// Image

import * as process from "process";

import Markdown from "markdown-to-jsx";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const CHAT_URL = process.env["REACT_APP_CHAT_URL"];

function getCookieValue(cookieString, cookieName) {
  const cookies = cookieString.split("; ");
  for (let cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return undefined;
}

function Chat() {
  const [messageDraft, setMessageDraft] = useState("");
  const [sentMessage, setSentMessage] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prevMessages, setPrevMessages] = useState([]);

  function getLoadingMessage() {
    return (
      <MKTypography
        variant="body2"
        color="black"
        mb={3}
        sx={{
          whiteSpace: "pre-wrap",
          fontStyle: isLoading ? "italic" : undefined,
        }}
      >
        {"Looking for the perfect answer to your question..."}
      </MKTypography>
    );
  }

  function formatMessage(message, isOutgoing) {
    const color = isOutgoing ? "grey-100" : "grey-200";
    const width = isOutgoing ? undefined : "85%";
    const align = isOutgoing ? "flex-end" : "flex-start";
    const marginL = isOutgoing ? undefined : 4;

    return (
      <Grid container alignItems={align} flexDirection="column" mb={3} width={width}>
        <Grid item>
          <MKBox py={1} px={2} bgColor={color} borderRadius="xl">
            <MKTypography
              variant="body2"
              color="black"
              sx={{ whiteSpace: "pre-wrap" }}
              ml={marginL}
            >
              <Markdown>{message}</Markdown>
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {/* <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/products/chat",
            label: "get a quote",
            color: "info",
          }}
        />
      </MKBox> */}
      <Grid
        container
        spacing={3}
        alignItems="center"
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <Grid item width="100%" height="100%">
          <MKBox
            bgColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Grid container py={1} px={2}>
              <Grid item xs>
                <MKBox component={Link} to="/" lineHeight={1}>
                  <MKTypography variant="button" fontWeight="bold" color="dark">
                    Radiens-AI
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item textAlign="center">
                <MKBox lineHeight={1}>
                  <MKTypography variant="button" fontSize={14} lineHeight={1}>
                    Chatting with AI Assistant
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
            <MKBox
              px={5}
              py={2}
              flexGrow={1}
              display="flex"
              flexDirection="column"
              height="calc(100% - 2.5rem)"
            >
              {!sentMessage && !response ? (
                <MKBox mt={4}>
                  <MKTypography variant="h5" color="text" mb={3} textAlign="center">
                    What can I help you with?
                  </MKTypography>
                </MKBox>
              ) : (
                <MKBox overflow="auto" flexGrow={1} maxHeight="100%">
                  {prevMessages.map((m, i) => formatMessage(m, i % 2 == 0))}
                  {formatMessage(sentMessage, true)}
                  {isLoading ? getLoadingMessage() : formatMessage(response, false)}
                </MKBox>
              )}
              <MKBox
                mt={2}
                width="100%"
                component="form"
                method="post"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  setMessageDraft("");
                  setSentMessage(messageDraft);
                  setIsLoading(true);
                  const threadId = getCookieValue(document.cookie, "threadId");
                  axios
                    .post(
                      CHAT_URL,
                      {
                        message: messageDraft,
                        threadId,
                        nPrevMessages: threadId && !response ? -1 : undefined,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((e) => {
                      console.log(e.data);
                      setResponse(e.data.message.text.value);
                      if (e.data?.prevMessages) {
                        setPrevMessages(e.data.prevMessages.map((m) => m.text.value));
                      }
                      document.cookie = `threadId=${e.data.threadId}`;
                    })
                    .finally((args) => {
                      setIsLoading(false);
                      console.log(args);
                    });
                }}
              >
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item xs>
                    <MKInput
                      variant="outlined"
                      placeholder="Chat here..."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={messageDraft.split("\n").length}
                      value={messageDraft}
                      onChange={(e) => setMessageDraft(e.target.value)}
                    />
                  </Grid>
                  <Grid item sx={{ paddingLeft: "0.5rem" }}>
                    <MKButton variant="gradient" color="info" type="submit">
                      <ArrowUpwardIcon />
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </>
  );
}

export default Chat;
